import { test1 } from "./test/test1";
import { test2 } from "./test/test2";
import { test3 } from "./test/test3";
import { test4 } from "./test/test4";



//카테고리 타입
export const categoryType = [
  { id: 1, name: "방향성" },
  { id: 2, name: "속도" },
  { id: 3, name: "포지셔닝" },
];

/**
 * 모든 테스트 문제들 배열입니다.
 * 여기에 test 추가하시면 됩니다.
 * 1. test는 data/test test1.js 파일과 동일한 데이터 형태로 만들고
 * 2. 맨 위 import로 test1 처럼 imoprt 하시고 배열 안에 넣으시며 됩니다.
 *
 * 만약 question2를 만드신다면 1,2를하시고
 * export const allTest = [test1, test2]; <<----- 이렇게 넣으시면
 * 등록 완료 입니다.
 */
export const allTest = [test1, test2, test3, test4];

export const getCategory = (id) => {
  return categoryType.find((item) => item.id === id);
};
