
import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Main } from "./router/main/Main";
import styled from "styled-components";

import { ReactComponent as Burger } from "./assets/svg/burger.svg";
import { TestMain } from "./router/test-main/TestMain";
import { Test } from "./router/test/Test";
import { Result } from "./router/result/Result";
import { useState } from "react";

const App = () => {
  const [openMenu, updateOpenMenu] = useState(false);

  return (
    <BrowserRouter>
      <Container>
        <LayoutContainer>
          <BodyContainer>
            <MainHeader>
              <div className="group" style={{ gap: 14 }}>
                <Burger
                  className="btn"
                  width={35}
                  height={35}
                  onClick={() => updateOpenMenu((prev) => !prev)}
                />
              </div>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <LogoText>
                  <span style={{ color: "#191919" }}>바싹 마른</span>
                  <span style={{ color: "#FF8000" }}> 망고 </span>
                </LogoText>
              </Link>
              <div className="group" style={{ gap: 14, opacity: 0 }}>
                <Burger width={28} height={28} />
              </div>
            </MainHeader>
            <MainBody>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/test-main/:id" element={<TestMain />} />
                <Route path="/test-main/:id/test" element={<Test />} />
                <Route
                  path="/main-test/:id/result/:count"
                  element={<Result />}
                />
              </Routes>
            </MainBody>
          </BodyContainer>
          {openMenu && <Dimd onClick={() => updateOpenMenu(false)} />}
          <Sidemenu className={`${openMenu ? "side__menu__open" : ""}`}>
            <SideInfo>
              <span style={{ color: "#191919" }}>
                바싹 마른 <span style={{ color: "#F5931F" }}>망고</span>
              </span>
            </SideInfo>
            <LinkItem
              href="https://moistnarratives.imweb.me/31/?idx=15"
              target="_blank"
            >
              실용적인 기록을 위한 : Practical Notion
            </LinkItem>
            <LinkItem
              href="https://blog.naver.com/moistmarketer/223225286890"
              target="_blank"
            >
              칼럼 : 각자의 난이도로 살아간다는 것은
            </LinkItem>
            <LinkItem
              href="https://blog.naver.com/moistmarketer/222726304393"
              target="_blank"
            >
              제작자 촉촉한마케터 소개
            </LinkItem>
            <LinkItem
              href="https://kmong.com/@%EC%B4%89%EC%B4%89%ED%95%9C%EB%A7%88%EC%BC%80%ED%84%B0"
              target="_blank"
            >
              단상을 판매하다
            </LinkItem>
            <LinkItem
              href="https://blog.naver.com/moistmarketer/222433371074/"
              target="_blank"
            >
              모이스트 노트(뒷이야기)
            </LinkItem>
            <LinkItem
              
              href="https://www.instagram.com/moist_mlab/"
              target="_blank"
            >
              인스타그램
            </LinkItem>
            <LinkItem
              href="https://writing.moistmarketer.com/"
              target="_blank"
            >
              강제 글쓰기 사이트 : 모이스트 망고
            </LinkItem>
          </Sidemenu>
        </LayoutContainer>
      </Container>
    </BrowserRouter>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: white;
  box-sizing: border-box;
`;

const LayoutContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 448px;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;

  border-left: 1px solid black;
  border-right: 1px solid black;

  overflow: hidden;
  .main__body {
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const Dimd = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
`;

const Sidemenu = styled.div`
  position: absolute;
  top: 0;
  left: -80%;
  width: 80%;
  height: 100%;
  background-color: white;
  z-index: 11;

  display: flex;
  flex-direction: column;

  transition: all 0.2s ease;

  &.side__menu__open {
    left: 0;
  }
`;

const SideInfo = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: #133337;

  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  letter-spacing: -0.14px;
`;

const LinkItem = styled.a`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 38px;

  text-decoration: none;

  color: #1f2022;

  /* heading/14-md */

  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.14px;
`;

const MainHeader = styled.div`
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px 12px 0px;
  .group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo {
    width: 150px;
    cursor: pointer;
  }

  .btn {
    cursor: pointer;
  }
`;

const MainBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const LogoText = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 200;
  line-height: 28px; /* 155.556% */
  letter-spacing: -1.2px;
`;

export default App;