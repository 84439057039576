import Q1ThumbNail from "../../assets/test/test1/thumbnail.png";
import Type0 from "../../assets/test/test1/1.png";
import Type1 from "../../assets/test/test1/2.png";
import Type2 from "../../assets/test/test1/3.png";
import Type3 from "../../assets/test/test1/4.png";

/**
 * 인터페이스 설명
 * {
 *    id: number -------------------- 테스트 식별 아이디
 *    categoryId: number ------------ 카테고리 아이디 (data.js 파일에 있습니다.)
 *    thumbnail: string ------------- 카드 썸네일 입니다. 위에서 import 합니다.
 *    title: string ----------------- 테스트 제목입니다.
 *    subTitle: string -------------- 테스트 부 제목입니다.
 *    description: string ----------- 테스트 설명 입니다.
 *    totalQuestion: number --------- 총 질문 개수 입니다. (없어도 무방, 현재 사용하지 않음)
 *    kakaoShareId: number ---------- 카카오 공유 템플릿 ID
 *    shareImage: string ------------ 카카오 공유 이미지
 *    subjectQuestion: {
 *        subject : string ----------------- 주제 (사용 하지 않음)
 *        shortLabel: string --------------- 라벨 (사용 하지 않음)
 *        positiveResult: string ----------- + 워딩 (사용 하지 않음)
 *        negativeResult: string ----------- - 워딩  (사용 하지 않음)
 *        qeustions: {
 *            no: number -------------------------- 문제 번호
 *            content: string --------------------- 질문
 *            answer: {
 *                constents: string --------------------------- 답변
 *                value: 1 | -1 ------------------------------- 값
 *            }
 *        }[] <----배열이라는 뜻
 *    }[]
 *    result: {
 *        type: string -------------- 결과 type,
 *        const: number ------------- 결과 카운트 (결과 식별자로 사용)
 *        image: string ------------- 결과 이미지 입니다. 위에서 import 합니다.
 *        description: string ------- 결과 설명 입니다.
 *    }[]
 * }
 */
export const test1 = {
  id: 1,
  categoryId: 3,
  thumbnail: Q1ThumbNail,
  title: "기록 방향성 테스트",
  subTitle: '무턱대고 기록하지말고, 성향에 맞춰 실용적으로',
  description: `나는 무엇을 위해 기록하는 것일까.\n'나같은 사람들'에게 어떤 도움이 될 수 있을까.\n\n14개의 질문을 통해, 4개의 유형 중\n본인에게 가장 적합한 결과지를 받아보게 됩니다.`,
  totalQuestion: 14,
  kakaoShareId: 102380,
  shareImage:
    "https://i.ibb.co/7twKcj2/9.jpg",
  subjectQuestion: [
    {
      subject: "쌓는다는것에 대한 나의 반응",
      shortLabel: "저항의 존재 유무",
      positiveResult: "쌓는다는 것에 대한 두려움",
      negativeResult: "쌓음에 대한 저항없음",
      questions: [
        {
          no: 1,
          content: "내, '배움 충동'이 올라오는 순간은? \n조금이라도 더 혹하는 순간. 사진 촬영 클래스라면.",
          answer: [
            {
              contents: "내, ‘사진촬영’ 실력 자체는 그대로여도,\n자랑할만한 사진을 몇개 쥐어주는 클래스",
              value: 1,
            },
            {
              contents: "당장, 자랑할만한 사진을 쥐어주지는 않지만,\n사진촬영 실력 자체를 길러주는 클래스. (장기)",
              value: -1,
            },
          ],
        },
        {
          no: 2,
          content: "무언가를 새롭게 배울 때의 부정적인 생각은?\n어떠한 부정적인 생각이 주로 떠오르는지.",
          answer: [
            {
              contents: "이걸로, 언제 자리잡지...\n나도 나만의 분야가 하나쯤은 있어야 하는데.",
              value: 1,
            },
            {
              contents: "에휴 생각보다 훨씬 귀찮은데?\n그냥 하던거나 계속 하자. 나와는 맞지 않는 분야네.",
              value: -1,
            },
          ],
        },
        {
          no: 3,
          content: "1년간 배운 것들을 되돌아보자.\n내가 더 많이 고른 선택지는?",
          answer: [
            {
              contents: "내 정체성이 확고해지는 클래스. 한 단어로 나를 설명.\n‘작가'라거나 '유튜버'가 되기 위한 과정.",
              value: 1,
            },
            {
              contents: "내 정체성에 한스푼 더하고 싶을 때.\n나는 에세이 작간데, 사진도 잘찍는 작가.",
              value: -1,
            },
          ],
        },
        {
          no: 4,
          content: "커리어 관련, 내 불안함 혹은 스트레스\n주된 이유를 하나 꼽아보자면...",
          answer: [
            {
              contents: "나를 설명하지 못한다는 아쉬움. \n떳떳한, 혹은 그럴싸한 직업이나 단어를 갖고 싶다.",
              value: 1,
            },
            {
              contents: "밋밋하고, 뻔해서 스트레스다.\n ‘나다움’이 없어. 그냥 대체되는 사람같아.",
              value: -1,
            },
          ],
        },
        {
          no: 5,
          content: "요즘은 새로운 무언가를 배울 때, 두렵더라고.\n생각해봤어. 그 이유가 무엇이냐면...",
          answer: [
            {
              contents: " 실질적으로 내게 남는 것이 없다는 생각.\n할 수는 있는데, 내 삶은 그대로인.",
              value: 1,
            },
            {
              contents: "무언가를 끝내지 못하는 것. \n하다 중간에 그만둘까봐.",
              value: -1,
            },
          ],
        },
        {
          no: 6,
          content: "더 소중하다고 생각하는 경험은?\n내게 더 가치있는 무언가.",
          answer: [
            {
              contents: "특별한 계기, 순간. 강렬한 경험 하나.\n전환점이라거나, 성공, 실패 경험의 순간.",
              value: 1,
            },
            {
              contents: "오랜 시간 나와 함께하는 무언가.\n습관, 사람, 모임, 물건 등과 얽힌.",
              value: -1,
            },
          ],
        },
        {
          no: 7,
          content: "듣기 싫은 말. 괜히 '긁히는' 그런 말 있잖아.\n 혹은 '나를 이렇게 볼까봐 두렵다'에 해당하는.",
          answer: [
            {
              contents: "아... 제대로 된 직업은 없으시고요?\n네 뭐... 알겠습니다.",
              value: 1,
            },
            {
              contents: "아... 그 직업은 너무 재미없지 않아요?\n 지루하고, 뻔할 것 같아서 전 못하겠던데... ",
              value: -1,
            },
          ],
        },
      ],
    },
    {
      subject: "전달방식",
      shortLabel: "전달방식",
      positiveResult: "내 고군분투",
      negativeResult: "도움이 되는 실질적인 팁",
      questions: [
        {
          no: 15,
          content: "나름 성공한 당신.\n같은 길을 걷고자 하는 후배들에게 강연을.",
          answer: [
            {
              contents: "'나도 너랑 같은 고민을 했었다'라는 것을 보여줘야지. \n 그래야 몰입할거야. 최대한 내 고군분투 위주로.",
              value: 1,
            },
            {
              contents: "고군분투보다는, 내 노하우나 결론 위주로 전달해야지.\n 나는 동질감을 유도하고싶지는 않은데?",
              value: -1,
            },
          ],
        },
        {
          no: 16,
          content: "연애, 서바이벌 등 예능을 볼 때에도\n나는 이런 생각을 하는 것 같다.",
          answer: [
            {
              contents: "서사가 궁금하다.\n 중간을 스킵하는 일은 거의 없어.",
              value: 1,
            },
            {
              contents: "미안하지만 결론만 이야기하자. \n 누구랑 누구랑 이어졌는데.",
              value: -1,
            },
          ],
        },
        {
          no: 17,
          content: "가끔은 내가 사이코인것 같기도 하다. \n아주 가끔이지만, 왜냐면...",
          answer: [
            {
              contents: "다른 사람들의 실패, 아픔 관련 글을 보며\n위안을 얻거나, 안도감, 우월감을 느끼는 것 같아서.",
              value: 1,
            },
            {
              contents: "누군가, 본인 이야기를 조금만 길게 해도 \n'아니 그래서 결론이 뭔데'라는 생각이 들거든.",
              value: -1,
            },
          ],
        },
        {
          no: 18,
          content: "누군가 내게 영화나 드라마 등을 추천한다면\n'이것 한번 꼭 봐봐'라는 말을 듣는다면.",
          answer: [
            {
              contents: "권하는 사람의 텐션에 따라 결정하는 편이다.\n 가볍게 툭 던질때는 무덤덤. 눈 크게 뜨면서 강조하면 호기심.",
              value: 1,
            },
            {
              contents: "권하는 사람의 텐션은 크게 중요하지 않고, \n 해당 작품의 포스터나 줄거리 등 대략적인 느낌이 중요하다.",
              value: -1,
            },
          ],
        },
        {
          no: 19,
          content: "내 삶을 돌이켜보면,\n이런 질투심을 더 많이 - 자주 느꼈던 것 같다.",
          answer: [
            {
              contents: "스토리 기반의 질투심\n'나보다 쟤랑 더 친하네.', '쟤는 행복한가봐 항상 웃네.'",
              value: 1,
            },
            {
              contents: "사회적인 시선 기반의 질투심\n'와, 쟤 서울대야?', '금수저였구나? 역시...'",
              value: -1,
            },
          ],
        },
        {
          no: 20,
          content: "어렸을 때와 비교해보자.\n지금의 나는 보다 더...",
          answer: [
            {
              contents: "과정을 중시하는 사람이 되었다.\n실패했더라도, 과정에서 의미를 찾을 수 있게 되었다. 조금은.",
              value: 1,
            },
            {
              contents: "어렸을때와는 다르게, 보다 결과를 중시하게 되었다.\n그래서, 결국 합격했는가. 성공했는가. 뭐 이런.",
              value: -1,
            },
          ],
        },
        {
          no: 21,
          content: "그래. 나, 떳떳하지 않아. \n어떤식으로 떳떳하지 않냐면 말이지...",
          answer: [
            {
              contents: "없는 '이야기'를 잘 지어내는 편이야.\n사실 그 선물, 네가 떠올라서 산게 아니라, 집에 있던거야.",
              value: 1,
            },
            {
              contents: "'있어보이는' 이야기를 잘 지어내는 편이야.\n 어차피 한 번 보고 안 볼 사인데.'저 서울대나왔어요.' ",
              value: -1,
            },
          ],
        },
      ],
    },
  ],
  result: [
    {
      type: "",
      count: 0,
      image: Type2,
      description:
        "",
    },
    {
      type: "",
      count: 1,
      image: Type3,
      description:
        "",
    },
    {
      type: "",
      count: 2,
      image: Type0,
      description:
        "",
    },
    {
      type: "",
      count: 3,
      image: Type1,
      description:
        "",
    },
  ],
};
